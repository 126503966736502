@-webkit-keyframes fadeInDown {
        0% {
                opacity: 0;
                transform: translateY(-20px);
        }
        100% {
                opacity: 1;
                transform: translateY(0);
        }
} 

@keyframes fadeInDown {
        0% {
                opacity: 0;
                transform: translateY(-20px);
        }
        100% {
                opacity: 1;
                transform: translateY(0);
        }
}

@-webkit-keyframes glow {
        0% {
                opacity: 0.4;
        }
        100% {
                opacity: 1;
        }
} 

@keyframes glow {
        0% {
                opacity: 0.4;
        }
        100% {
                opacity: 1;
        }
}

@keyframes slide {
        100% {
                transform:translate(0px);
                opacity: 1;
        }
}

@-webkit-keyframes slide {
        100% {
                -webkit-transform:translate(0);
                opacity: 1;
        }
}

@-moz-keyframes slide {
        100% {
                -moz-transform:translate(0);
                opacity: 1;
        }
}

@-o-keyframes slide {
        100% {
                -o-transform:translate(0);
                opacity: 1;
        }
}

@-ms-keyframes slide {
        100% {
                -ms-transform:translate(0);
                opacity: 1;
        }
}