html body{
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 300;
    letter-spacing: 1px;
    color: #444444;
}

.mb-6, .my-6 {
    margin-bottom: 4rem !important;
}

section.service-landing {
    overflow: hidden;
}
.services_h_three_mod .accordion_header {
    display: flex;
    align-items: center;
}
.dark-bg-list {
    background-color: #eee;
    display: flex;
    list-style: none;
    column-gap: 8px;
    line-height: 16px;
    padding: 16px;
    align-items: center;
    width: 100%;
    justify-content: center;
}
.dark-bg-list li:last-child::after {
    display: none;
}
.dark-bg-list li::after {
    content: "\2192";
    margin-left: 8px;
}
b, strong {
    font-weight: bold;
}
body.services_page.landing_page #content-row{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #656565;
}

body.services_page.landing_page .services_content .category-leftcol-12{
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 300;
}

body a{
    color: #444444;

}

.category-description a, .item-content a{
    font-weight: 600;
    text-decoration: underline;
}

.category-description a:hover, .item-content a:hover{
    color: #222222;
    font-weight: 600;
    text-decoration: underline;
}

#content-row .cat-items-row a, #content-row .cat-items-row a:hover{
    text-decoration: none;
}

body .page-link{
    color: #444444;
}

body a:hover{
    color: #222222;

}

body .btn-primary{
    background: none;
    border: 1px solid #888888;
    color: #fff;
    text-transform: uppercase;
    font-size: 0.9375rem;
    border-radius: 35px;
    padding: 1.25rem 2.125rem;
    letter-spacing: 5px;
    border: 1px solid;
}

body .btn-primary:hover{
    background: #eeeeee;
    color: #363636;
    border: 1px solid #eeeeee;
}

body .btn-dark{
    font-family: "Source Sans Pro", sans-serif;
    background-color: #222222;
    border-color: #222222;
    border-radius: 40px;
    padding: 1.5rem 2.5rem;
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    text-transform: uppercase;
    font-size: 1.125rem;
    font-weight: 700;
    letter-spacing: 4.5px;
}

body .btn-dark:hover{
    color: #222222;
    background-color: #fff;
    border-color: #222222;
}

.lang-active a{
    font-weight: 600;
}

div.mod-languages a {
    color: #828282;
}

.custom ul{
    padding-left: 0;
}

.custom ul li{
    margin-bottom: 10px;
    list-style-type: none;
    vertical-align: top;
}

.custom ul li:before{
    margin-right: 10px;
    font-family: 'Pe-icon-7-stroke';
    content: "\e685";
    vertical-align: middle;
}


#footer-a-row{
    padding: 80px 0 80px 0;
}

.main-bottom-b{
    padding-bottom: 0rem;
    padding-top: 7rem;
}

body.about_us_page .main-bottom-b{
    padding-bottom: 6rem;
    padding-top: 6rem;
}

body.about_us_page .moduletable.logo_line_mod{
    padding-bottom: 8rem;
}

.main-top-c{
    padding-top: 30px;
    padding-bottom: 30px;
}

body.home_page #bottom-c-row{
     padding-top: 30px;
    padding-bottom: 30px;
}

body.blog_page .main-top-c{
    padding-top: 0;
    padding-bottom: 0;
}

.main-bottom-c{
    padding-top: 30px;
    padding-bottom: 15px;
}

.custom-mb-40{
    margin-bottom: 40px;
}

.frontpage-contactus div.module-inner{
    padding-left: 90px;
    padding-right: 75px;
    padding-top: 35px;
}


body .mod_its_form_btn{
    background-color: unset;
    color: #363636;
    padding: 12px 30px;
    border-color: #888;
    border-radius: 40px;
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}

body .mod_its_form_btn:hover{
    background-color: #222;
    border-color: #222;
    color: #fff;
}

body .mod_its_form_text,
body .mod_its_form_email,
body .mod_its_form_tel,
body .mod_its_form_textarea,
body .mod_its_form_email:focus,
body .mod_its_form_tel:focus,
body .mod_its_form_textarea:focus{
    background-color: #eeeeee;
    border-color: #dddddd;
}

body .btn-light{
    border-color: #2A2A2A;
    font-size: 0.75rem;
    padding: 0.5rem 1.5rem;
    border-radius: 40px;
    background: transparent;
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}

body .btn-light:hover{
    border-color: #2A2A2A;
    color: #fff;
    background: #2A2A2A;
}

.customModuleTitle div{
    display: inline-block;
    margin-right: 0.4rem;
}

.customModuleTitle .titleFirst{
    font-weight: 900;
}

.moduletable.work-with-us .customModuleTitle{
    font-weight: 300;
    font-size: 3rem;
    letter-spacing: 14px;
}

.customLogo .customModuleTitle::before{
    content: url('../customTitleLogo.png');
    margin-right: 10px;
    border: 1px solid #dddddd;
    padding: 10px 3px 0px 3px;
}

.service-landing-d .mod-item-heading .customModuleTitle::before{
    content: url('../customTitleLogo.png');
    margin-right: 10px;
    border: 1px solid #dddddd;
    padding: 10px 3px 0px 3px;
}

.custom-logo-container .module-inner .module-content{
    padding-left: 65px;
}

/** MENU **/
#top-a-row .main_menu a:active,
#top-a-row .main_menu a{
    color: #222;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
}

#top-a-row .main_menu a:hover{
    color: #aaa;
}
#top-a-row{
    background: none;
    transition: all 0.5s;
}

#top-a-row.menu-background{
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
    transition: all 0.5s;
}



/** MarketRats **/

.main-top-a{
    padding-top: 5rem;
    padding-bottom: 5rem;
}

#bottom-b-row .container-fluid{
    padding: 0 15px 0 0;
}

/** Work with us **/

.main-top-b{
    padding-top: 6rem;
    padding-bottom: 6.5rem;
}

.main-top-b .work-with-us header{
    position: relative;
    left: -200px;
    width: 130%;
}

.main-top-b .work-with-us h2.moduleTitle{
    font-size: 4rem;
}

.main-top-b .work-with-us h2.moduleTitle .titleFirst{
    letter-spacing: .8rem;
}

.work-with-us .mod-item-heading a{
    color: #212529;
    text-transform: uppercase;
    font-size: 1.125rem;
    font-weight: 400;
    letter-spacing: 3px;
}

.work-with-us .btn-dark{
    background-color: #222222;
    border-color: #222222;
    border-radius: 40px;
    padding: 1.5rem 2.5rem;
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
    text-transform: uppercase;
    font-size: 1.125rem;
    font-weight: 700;
    letter-spacing: 4.5px;
}

.work-with-us .btn-dark:hover{
    color: #222222;
    background-color: #fff;
    border-color: #222222;
}

/** OUR CLIENTS **/

#main-bottom-a-row .container-fluid{
    padding-top: 0;
    padding-bottom: 0;
}

/** READ ABOUT BLOG **/

.blog_module .btn-light{
    text-transform: uppercase;
}

.customModuleTitle{
    font-weight: 300;
    letter-spacing: 8px;
}

.customModuleTitle{
    text-transform: uppercase;
}

.title_line{
    position: relative;
}
.service-landing-d .mod-item-title {
    text-align: left;
}
.service-landing-d {
background-image: none !important;
}
.service-landing-d .row-container .container .row .row {
    padding-top: 2rem;
}
.service-landing-d ul {
    padding-left: 0;
}
.service-landing-d .mod-item-heading {
    position: relative;
}

.title_line:before,
.service-landing-d .mod-item-heading:before
{
    /*content: url('../customTitleLogo.png');*/
    content: ' ';
    height: 1px;
    width: 1000px;
    background-color: #dddddd;
    position: absolute;
    left: -1000px;
    top: 19px;
}

/* content */

/*body.services_page section.content{
    background-image: url('../images/bkg2.png');
    background-repeat: no-repeat;
    background-position:  right -40px;
}*/


#content-row{
    padding-top: 3rem;
    padding-bottom: 7rem;
}

.content-wrapper {
    margin-top: 70px;
}
body.clients_page #content-row{
    padding-top: 0;
    padding-bottom: 0;
}


body.about_us_page #content-row{
    padding-top: 7rem;
    padding-bottom: 7rem;
}

body.about_us_page #content-row{
    padding-bottom: 2rem;
}

body.contact_page #content-row{
    padding-top: 5rem;
    padding-bottom: 4rem;
}

.page-title{
    margin-bottom: 5rem;
    text-transform: uppercase;
    font-size: 2.438rem;
}

.page-title strong{
    font-weight: 900;
}

#bottom-a-row{
    padding-top: 7rem;
    padding-bottom: 7rem;
}


/*slick*/

.slick-slide img{
        margin: auto;
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
}

.slick-arrow{
        position: absolute;
        z-index: 10;
         top: 50%;
        margin-top: 0;
        background: none;
        border: none;
        font-style: 0;
        line-height: 0;
        color: transparent;
}

.slick-arrow:focus{
        outline: none;
}

.slick-prev.slick-arrow{
        left: -30px;
}

.slick-prev.slick-arrow:before{
        font-family: 'Pe-icon-7-stroke';
        content: "\e686";
        font-size: 4rem;
        color: #444;
}

.slick-next.slick-arrow{
        right: -30px;
}

/* .slick-next.slick-arrow:after{
        font-family: 'Pe-icon-7-stroke';
        content: "\e684";
        font-size: 4rem;
        color: #444;
} */

.moduletable.logo_line_mod .mod-item-image{
        margin: 0;
}

.moduletable.logo_line_mod{
        padding-top: 3rem;
        padding-bottom: 3rem;
}

.moduletable.social_mod {
    display: flex;
    margin-left: auto;
    align-items: center;
    justify-content: right;
}

.moduletable.social_mod img {
    width: 28px;
}

.moduletable.toggle {
    margin: auto 0;
}

.read-more-link {
    border: 0;
    background: transparent;
    font-weight: 700;
    text-transform: uppercase;
}

.mod-full-text p{
    margin: 0;
}
.mod-full-text br {
    display: none;
}

.reviews_top {
    text-align: center;
    background: #222222;
    color: #ffffff;
    padding: 40px 22px 16px;
    font-size: 18px;
    font-weight: 400;
}

.reviews_top h3 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
}

.reviews_top p {
    margin-bottom: 20px;
}

.author_name a {
    color: #4384F7;
    font-weight: 600;
}
.stars_line span .fa{
    color: #FFED27;
}
.p-4 {
    padding: 32px;
}

.reviews {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.15);
}

.reviews .slick-carousel button.slick-prev.slick-arrow {
    left: 20px;
}

.reviews .slick-carousel button.slick-next.slick-arrow {
    right: 20px;
}

.review_text {
    font-size: 16px;
    text-align: center;
    font-weight: 600;
    padding: 35px;
    padding-top: 0;
}

.reviews_info small {
    font-weight: 600;
    color: #838383;
}

.reviews_info .stars {
    color: #FFED27;
}

.review_text::before {
    content: '„';
    margin-right: -5px;
}

.review_text::after {
    content: '“';
    margin-left: -5px;
}

.service-landing-j .moduletable {
    padding: 40px;
}
.google {
    background: url('../../../images/google.png') no-repeat;
    width: 173px;
    height: 24px;
    display: block;
    margin-left: auto;
    margin-top: 46px;
}